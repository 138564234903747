import { useLinkTo } from '@react-navigation/native';
import { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Linking } from 'react-native';
import { SwitchPracticeResponse } from 'src/api';
import sentry from 'src/utils/sentry';
import { AuthStatus } from '../../providers/AuthProvider/model';
import {
  qualifiedAuthStatus,
  shouldSwitchUsers,
  handlePracticeSwitch,
  isLoginPath,
  urlAllowsNoAuth,
  pathAndParams,
  urlEmitter,
  urlInfo,
  LINKING_ERRORS,
  cleanURL
} from './helper';
import { UrlInfo } from './model';
import { appLinkingPaths } from 'src/utils';
import { ROOT_STACK_PATHS } from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { reportError, displayDeeplinkError, resolveOrCatchSendGridLink } from './errorHandlers';

interface InitialDeeplinkProps {
  /**
   * Has the client profile been fetched from the api.
   */
  ready: boolean;
  logOut: (switchPractice?: SwitchPracticeResponse | undefined) => Promise<void>;
  authStatus: AuthStatus | undefined;
}

/**
 * If the user is not authenticated, useDeeplink will capture the deeplink.
 * Once the user is authenticated, it will navigate to the stored deeplink.
 */
const useDeeplink = ({ ready, authStatus, logOut: applyPractice }: InitialDeeplinkProps) => {
  const [urlToHandle, setUrlToHandle] = useState<UrlInfo | undefined>();
  const linkTo = useRef(useLinkTo());

  useEffect(() => {
    // Handles urls passed from notifications to the urlEmitter
    if (urlInfo) {
      setUrlToHandle(urlInfo);
    }
    const callback = (urlInfo: UrlInfo) => {
      setUrlToHandle(urlInfo);
    };
    urlEmitter.on('url', callback);
    return () => {
      urlEmitter.removeListener('url', callback);
    };
  }, []);

  /**
   *Parse and store a deeplink
   */
  useLayoutEffect(() => {
    if (Linking) {
      const abortCtl = { ctl: new AbortController() };
      const callback = (url: string | null) => {
        if (!abortCtl.ctl.signal.aborted) abortCtl.ctl.abort();
        abortCtl.ctl = new AbortController();
        void resolveOrCatchSendGridLink(url, setUrlToHandle, abortCtl.ctl.signal);
      };
      void Linking.getInitialURL().then(async (url) => callback(url));
      const sub = Linking.addEventListener('url', ({ url }) => callback(url));
      return () => {
        try {
          if (sub.remove) {
            sub.remove();
          } else {
            Linking.removeAllListeners('url');
          }
        } catch {
          sentry.addBreadcrumb({
            message: 'Failed to remove deeplink listener',
            category: 'deeplink',
            type: 'error'
          });
        }
      };
    }
  }, []);

  /**
   * Once the user is logged in, navigate to the deeplink.
   */
  const handleDeeplink = useCallback(async () => {
    if (!ready || !urlToHandle || !qualifiedAuthStatus(authStatus)) return;
    const isLoggedIn = authStatus === AuthStatus.AUTHENTICATED;
    if (isLoggedIn) {
      if (await shouldSwitchUsers(urlToHandle)) {
        return handlePracticeSwitch(urlToHandle, applyPractice);
      }
    }
    const url = cleanURL(urlToHandle.url);
    if (isLoginPath(url)) return;
    if (!appLinkingPaths(ROOT_STACK_PATHS).includes(url.pathname.replace(/^\//, ''))) {
      displayDeeplinkError(urlToHandle, LINKING_ERRORS.NOT_FOUND);
      reportError(urlToHandle, LINKING_ERRORS.NOT_FOUND);
      setUrlToHandle(undefined);
      return;
    }
    if (!isLoggedIn && !urlAllowsNoAuth(url)) {
      displayDeeplinkError(urlToHandle, LINKING_ERRORS.UNAUTHORIZED);
      return;
    }
    try {
      linkTo.current(pathAndParams(url));
    } catch (e) {
      displayDeeplinkError(urlToHandle, LINKING_ERRORS.NAVIGATION);
      reportError(urlToHandle, LINKING_ERRORS.NAVIGATION, undefined, e as Error);
    } finally {
      setUrlToHandle(undefined);
    }
  }, [ready, urlToHandle, applyPractice, authStatus]);

  useEffect(() => {
    void handleDeeplink();
  }, [handleDeeplink]);
};

export default useDeeplink;
