import { endpoint } from 'src/constants';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const confirmEmail = async ({
  token,
  multipleAccounts,
  email
}: ConfirmationVariables): Promise<ConfirmationSuccess> => {
  const response = await apiClient.get(endpoint('EMAIL_CONFIRMATION'), {
    params: {
      confirmation_token: token,
      multiple_accounts: multipleAccounts,
      email
    }
  });
  return isDefinedOrThrowServerError(response.data, isConfirmation);
};

const isConfirmation = (data: any): data is ConfirmationSuccess => {
  return typeof data.success === 'string';
};

export interface ConfirmationVariables {
  token: string;
  multipleAccounts?: boolean;
  email?: string;
}

export interface ConfirmationSuccess {
  success: string;
}

export interface ConfirmationError {
  confirmation_token: string[];
  email: string[];
}
