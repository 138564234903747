import { t } from 'i18next';
import { SetStateAction } from 'react';
import Toast from 'react-native-toast-message';
import resolveSendGridLink, { validateHostname } from 'src/utils/resolveSendGridLink';
import sentry from 'src/utils/sentry';
import { LINKING_ERRORS } from './helper';
import { UrlInfo } from './model';
import { canOpenURL, openURL } from 'expo-linking';

/**
 * Attempts to resolve a url to handle, catching and displaying errors if needed.
 * @param url
 * @param setUrlToHandle
 * @param signal
 * @returns
 */
export const resolveOrCatchSendGridLink = async (
  url: string | null,
  setUrlToHandle: (value: SetStateAction<UrlInfo | undefined>) => void,
  signal: AbortSignal
) => {
  if (url) {
    return resolveSendGridLink(url, signal)
      .then((destination) => {
        handleSetUrlOrAbort(destination, setUrlToHandle, signal, true);
      })
      .catch(async (e) => {
        const shouldHandleExternally = validateHostname(url) && (await canOpenURL(url));
        const cause = e.cause as Response;
        const erroredUrl = { url: '', source: url };

        if (shouldHandleExternally) {
          sentry.addBreadcrumb({
            message: `Directing the user to browser for ${url}`,
            data: { url, cause: cause?.status },
            category: 'deeplink',
            type: 'error'
          });
          setTimeout(() => {
            if (!signal?.aborted) void openURL(url);
          }, 2000);
        }

        if (!signal?.aborted) {
          reportError(
            erroredUrl,
            cause ? LINKING_ERRORS.INVALID : LINKING_ERRORS.NETWORK,
            cause?.status ?? 'Network',
            e
          );
          displayDeeplinkError(
            erroredUrl,
            shouldHandleExternally
              ? LINKING_ERRORS.EXTERNAL
              : cause
              ? LINKING_ERRORS.INVALID
              : LINKING_ERRORS.NETWORK,
            cause?.status ?? 'Network'
          );
        }

        handleSetUrlOrAbort(erroredUrl, setUrlToHandle, signal, false);
      });
  }
};

/**
 * Sets the url to handle unless the fetch call has been aborted
 * @param destination
 * @param setUrlToHandle
 * @param signal
 */
const handleSetUrlOrAbort = (
  destination: UrlInfo,
  setUrlToHandle: (value: SetStateAction<UrlInfo | undefined>) => void,
  signal: AbortSignal,
  success: boolean
) => {
  let message: string = '';
  if (success) {
    if (signal.aborted) {
      message = `Deeplink resolution was cancelled by another call.`;
    } else {
      message = `Deeplink was resolved.`;
    }
  } else {
    message = `Deeplink resolution failed.`;
  }
  sentry.addBreadcrumb({
    message,
    data: destination,
    category: 'deeplink',
    type: signal.aborted || success ? 'debug' : 'error'
  });

  if (!signal.aborted) {
    setUrlToHandle(destination.url ? destination : undefined);
  }
};

/**
 * Captures an error and displays it to the user.
 * @param urlToHandle
 * @param type
 */
export const displayDeeplinkError = (urlToHandle: UrlInfo, type: LINKING_ERRORS, code?: number) => {
  Toast.show({
    type: 'error',
    text1: t(`errors.${type}.name`, { ns: 'deeplinking', code }),
    text2: `${t(`errors.${type}.message`, {
      url: urlToHandle.url,
      ns: 'deeplinking'
    })}`
  });
};

export const reportError = (
  urlToHandle: UrlInfo,
  type: LINKING_ERRORS,
  code?: number,
  e?: Error
) => {
  const error = new Error(
    `${t(`errors.${type}.message`, {
      url: urlToHandle.url,
      ns: 'deeplinking'
    })}`
  );
  error.name = t(`errors.${type}.name`, { lng: 'en', ns: 'deeplinking', code });
  error.cause = `${urlToHandle.source ?? urlToHandle.url} ${e?.message ?? ''}`;
  sentry.captureException(error);
};
