import { useQuery } from 'react-query';
import { getPatients, QueryKeys } from 'src/api';

const usePatients = () => {
  return useQuery({
    queryKey: [QueryKeys.PATIENTS],
    queryFn: getPatients
  });
};

export default usePatients;
