import config from 'src/environment';
import fetchRetry from '../fetchRetry';
import sentry from 'src/utils/sentry';
import { pick, uniqueId } from 'lodash';

export const resolveSendGridLink = async (source: string, signal?: AbortSignal) => {
  const requestId = uniqueId();
  if (validateHostname(source)) {
    return { url: await followRedirect(source, signal, requestId), source };
  } else {
    return { url: source };
  }
};

export const validateHostname = (url: string) => {
  const parsed = new URL(url);
  if (parsed.protocol !== 'https:') return false;
  for (const domain of config.DEEPLINK_REDIRECT_DOMAINS) {
    if (parsed.hostname === domain) return true;
  }
  return false;
};

export const followRedirect = async (url: string, signal?: AbortSignal, requestId?: string) => {
  sentry.addBreadcrumb({
    message: `Resolving deeplink`,
    category: 'deeplink',
    data: { url, requestId }
  });
  try {
    const response = await fetchRetry(
      url,
      {
        delay: 250,
        tries: 3,
        requestId,
        onfulfilled: async (response) => {
          if (!response.ok) {
            const error = new Error(`Status not ok`);
            error.name = `Deeplink Resolution Error`;
            await reportErrorAndResponse(response, error, requestId);
            throw error;
          }
          if (!response.url) {
            const error = new Error(`Missing Url`);
            error.name = `Deeplink Resolution Error`;
            await reportErrorAndResponse(response, error, requestId);
            throw error;
          }
          return response;
        },
        onFetchError: async (err) => {
          await reportErrorAndResponse(response, err, requestId);
          throw err;
        }
      },
      {
        redirect: 'follow',
        signal
      }
    );
    return response.url;
  } catch (error) {
    sentry.captureException(error, {
      tags: {
        section: 'deeplink'
      }
    });
    throw error;
  }
};

const reportErrorAndResponse = async (
  response: Response | undefined,
  error: Error,
  requestId?: string
) => {
  const text = await response?.text?.();
  const data = {
    response: {
      ...pick(response, ['type', 'status', 'ok', 'statusText', 'headers', 'url', 'statusText']),
      requestId,
      responseData: text
    }
  };
  sentry.addBreadcrumb({
    message: `${error.name}: ${error.message}`,
    category: 'deeplink',
    type: 'error',
    data
  });
};

export default resolveSendGridLink;
