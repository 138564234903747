import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AppointmentRequestSettings, OnlineBookingFormData, OnlineBookingState } from './model';
import { State } from 'src/module/FormKit/model';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { ApiPracticeInfo, Client, Openings, Patient, Request } from 'src/interfaces';
import { BOOKING_STEPS } from './helpers';
import { useMutation, useQuery } from 'react-query';
import useOnDemandPoll from './useOnDemandPoll';
import {
  ClientPatientSearchParams,
  QueryKeys,
  clientUnauthenticatedSearch,
  getPatients
} from 'src/api';
import useAppointmentOptions from 'src/hooks/useAppointmentOptions';
import { generateSubmissionValues, getInitialValues, isClientMatch } from './helper';
import { DiscardChangesModal } from 'src/components';
import { isDefinedOrThrowServerError, isPatientActive, queryClient } from 'src/utils';
import mapClientPatientSearch from './helpers/mapClientPatientSearch';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';
import { endpoint, TRACKING_EVENTS } from 'src/constants';
import useFilterAppointmentOptions from './helpers/useFilterAppointmentOptions';
import { useTrackVisit } from 'src/hooks';

const BookingContext = createContext<OnlineBookingState | undefined>(undefined);

export const useBookingState = () => {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw new Error('useBookingState must be used within a BookingStateProvider');
  }
  return context;
};

export const BookingStateConsumer = BookingContext.Consumer;

interface BookingProviderProps extends PropsWithChildren {
  practiceInfo: ApiPracticeInfo;
  user: Client | undefined;
  requestSetting: AppointmentRequestSettings;
  patient: Patient | undefined;
}

export const BookingProvider: React.FC<BookingProviderProps> = ({
  practiceInfo,
  children,
  user,
  requestSetting,
  patient
}) => {
  const [isAnotherAppointment, setIsAnotherAppointment] = useState(false);
  const { data: visitData, trackEvent } = useTrackVisit({
    initialEventName: TRACKING_EVENTS.APPOINTMENT_REQUEST_PAGE,
    practiceId: practiceInfo.practiceId,
    sourceId: practiceInfo.sourceId,
    clientId: user?.clientId
  });

  const [state, setState] = useState<State<BOOKING_STEPS>>({
    current: BOOKING_STEPS.CLIENT_INFO,
    visited: { [BOOKING_STEPS.CLIENT_INFO]: true }
  });

  const currentStep = state.current;

  const appointmentOptions = useAppointmentOptions(practiceInfo.practiceId);
  const [lastSubmittedValues, setLastSubmittedValues] = useState<
    Partial<OnlineBookingFormData> | undefined
  >();
  const initialValues = useMemo(
    () =>
      getInitialValues(
        user,
        appointmentOptions.data,
        patient,
        lastSubmittedValues,
        isAnotherAppointment
      ),
    [user, appointmentOptions.data, patient, lastSubmittedValues, isAnotherAppointment]
  );

  const formik = useFormik<OnlineBookingFormData>({
    initialValues,
    validateOnChange: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldValue }) => {
      trackEvent(TRACKING_EVENTS.APPOINTMENT_REQUEST_SUBMITTED);
      const submissionData = generateSubmissionValues(practiceInfo, values, visitData);
      const response = await unauthenticatedApiClient.post<Request>(endpoint('REQUESTS'), {
        request: submissionData
      });
      const data = isDefinedOrThrowServerError(response.data);
      await setFieldValue('requestId', data.id);
      setLastSubmittedValues({ ...values, requestId: data.id });
      queryClient.setQueryData([QueryKeys.REQUESTS, data.id], data);
    }
  });

  const { setFieldValue, submitForm, resetForm, values, dirty, validateField, validateForm } =
    formik;

  const { onDemandReady } = useOnDemandPoll(practiceInfo);

  const { mutateAsync, ...clientPatientQuery } = useMutation({
    mutationFn: async (args: ClientPatientSearchParams) => {
      if (user && isClientMatch({ practiceInfo, clientInfo: values.clientInfo, user })) {
        const patientData = await queryClient.fetchQuery<Patient[]>([QueryKeys.PATIENT], {
          queryFn: getPatients
        });
        const data = mapClientPatientSearch({
          patients: patientData.filter(isPatientActive),
          clientId: user?.clientId
        });
        return data;
      }
      const data = await clientUnauthenticatedSearch(args);
      return mapClientPatientSearch(data);
    }
  });
  useEffect(() => {
    if (clientPatientQuery.isSuccess) {
      void setFieldValue('clientInfo.clientId', clientPatientQuery.data?.clientId);
    }
  }, [clientPatientQuery.data?.clientId, clientPatientQuery.isSuccess, setFieldValue]);

  useEffect(() => {
    if (initialValues.clientInfo.emailAddress) {
      void mutateAsync({
        email: initialValues.clientInfo.emailAddress,
        practiceId: practiceInfo.practiceId,
        phoneNumber: initialValues.clientInfo.phoneNumber
      });
    }
  }, [
    mutateAsync,
    initialValues.clientInfo.emailAddress,
    practiceInfo.practiceId,
    initialValues.clientInfo.phoneNumber
  ]);

  const openingsQuery = useQuery({
    queryFn: async () => {
      await queryClient.invalidateQueries([
        QueryKeys.REQUEST_SETTING,
        practiceInfo?.practiceId,
        'online-booking'
      ]);
      const response = await unauthenticatedApiClient.get<Openings>(
        endpoint('SCHEDULE_OPENINGS', {
          practiceId: practiceInfo.practiceId
        }),
        {
          params: {
            appointment_type_id: values.appointmentInfo.appointmentOption?.value,
            category_id: values.appointmentInfo.columnOption?.categoryId
          }
        }
      );

      return isDefinedOrThrowServerError(response.data);
    },
    queryKey: [
      QueryKeys.SCHEDULE_OPENINGS,
      values.appointmentInfo.appointmentOption?.value,
      values.appointmentInfo.columnOption?.categoryId
    ],
    suspense: false,
    enabled: onDemandReady && !!state.visited[BOOKING_STEPS.SELECT_APPOINTMENT]
  });

  const advance = useCallback(async () => {
    if (currentStep === BOOKING_STEPS.CONFIRMATION) {
      void submitForm();
    }
    setState((prev) => {
      if (prev.current === BOOKING_STEPS.STATUS) return prev;
      let next: BOOKING_STEPS = prev.current + 1;
      if (next === BOOKING_STEPS.NOTIFICATION_SETTINGS && isAnotherAppointment) {
        prev.visited[next] = true;
        next += 1;
      }
      prev.current = next;
      prev.visited[next] = true;
      return { ...prev };
    });
  }, [currentStep, isAnotherAppointment, submitForm]);

  const goBack = useCallback(() => {
    setState(({ visited, current }) => ({ visited, current: current - 1 }));
  }, []);

  const goToStep = useCallback((step: BOOKING_STEPS) => {
    setState((prev) => {
      prev.current = step;
      prev.visited[step] = true;
      return { ...prev };
    });
  }, []);

  const bookAnotherAppointment = useCallback(async () => {
    const { clientInfo, notificationSettings } = lastSubmittedValues ?? {};

    setState({
      current: BOOKING_STEPS.PATIENT_INFO,
      visited: { [BOOKING_STEPS.CLIENT_INFO]: true, [BOOKING_STEPS.PATIENT_INFO]: true }
    });

    setLastSubmittedValues(undefined);
    setIsAnotherAppointment(true);
    resetForm();

    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        await Promise.all([
          queryClient.invalidateQueries([QueryKeys.SCHEDULE_OPENINGS]),
          queryClient.invalidateQueries([QueryKeys.ON_DEMAND_SYNC, practiceInfo.practiceId]),
          setFieldValue('clientInfo', { ...clientInfo }),
          setFieldValue('notificationSettings', { ...notificationSettings })
        ]);
        resolve();
      }, 0);
    });
  }, [lastSubmittedValues, practiceInfo.practiceId, resetForm, setFieldValue]);

  useFilterAppointmentOptions({
    openingsQuery,
    appointmentOptions,
    setFieldValue,
    validateField,
    practiceInfo,
    values
  });

  useEffect(() => {
    if (currentStep === BOOKING_STEPS.CONFIRMATION) {
      void validateForm();
    }
  }, [currentStep, validateForm]);

  const userFound = !!clientPatientQuery.data?.clientId || !!user;
  useEffect(() => {
    if (userFound) {
      trackEvent(TRACKING_EVENTS.APPOINTMENT_REQUEST_PAGE_CLIENT_FOUND);
    }
  }, [trackEvent, userFound]);

  if (!requestSetting || !appointmentOptions) {
    return null;
  }

  return (
    <BookingContext.Provider
      value={{
        advance,
        goBack,
        goToStep,
        onDemandReady,
        practiceInfo,
        requestSetting,
        appointmentOptions,
        clientPatientQuery: { ...clientPatientQuery, mutateAsync },
        openingsQuery,
        state,
        setState,
        bookAnotherAppointment,
        headerText: requestSetting.headerText,
        ...formik
      }}
    >
      {children}
      <DiscardChangesModal isDirty={dirty} />
    </BookingContext.Provider>
  );
};
