import { uniqueId } from 'lodash';
import sentry from './sentry';

const wait = async <T>(delay: number) => new Promise<T>((resolve) => setTimeout(resolve, delay));

interface RetryOptions {
  delay: number;
  tries: number;

  requestId?: string;

  /**
   * onFetchError is only called when a fetch throws on AbortError, NotAllowedError or TypeError.
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/fetch#exceptions
   *
   * onFetchError is expected to throw.
   *
   * @param reason
   * @returns never
   */
  onFetchError?: (reason: any) => never | PromiseLike<never>;

  onfulfilled?: (value: Response) => Response | PromiseLike<Response>;
}
const fetchRetry = async (
  url: string,
  retryOptions: RetryOptions,
  fetchOptions?: RequestInit
): Promise<Response> => {
  const requestId = retryOptions.requestId ?? uniqueId();
  const onError = async (err: any) => {
    if (fetchOptions?.signal?.aborted) {
      sentry.addBreadcrumb({
        message: `The request was aborted.`
      });
      throw err;
    } else if (retryOptions.tries <= 0) {
      sentry.addBreadcrumb({
        type: 'error',
        message: `Retry attempts exceeded.`,
        data: { url, requestId }
      });
      throw err;
    }
    sentry.addBreadcrumb({
      message: `Tries remaining: ${retryOptions.tries}`,
      data: { url, requestId }
    });
    return wait(retryOptions.delay).then(async () =>
      fetchRetry(url, { ...retryOptions, tries: retryOptions.tries - 1, requestId }, fetchOptions)
    );
  };

  return fetch(url, fetchOptions)
    .catch(retryOptions.onFetchError) // If this is entered, it must always rethrow an error.
    .then(retryOptions.onfulfilled)
    .catch(onError);
};

export default fetchRetry;
