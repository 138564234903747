import { IS_PROD } from 'src/constants';
import pkg from '../../../package.json';

export const interpolateName = (name: string, node?: React.ReactNode): string => {
  if (typeof node === 'string') {
    return `${name}: ${node}`;
  }
  return name;
};

export const getRelease = () => pkg.version;

export const SAMPLE_RATE = IS_PROD ? 0.05 : 1;

export const ignoreErrors = [
  'Non-Error exception captured',
  'Something went wrong!',
  'AxiosError',
  'CancelledError',
  'User action required' // Defined by us - only for log in errors
];
