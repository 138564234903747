import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from 'src/api';
import { getPatient } from 'src/api/patient';
import { Patient } from 'src/interfaces';
import { useTourGuideMaster } from 'src/module/TourGuide/TourGuideMaster';
import { useUser } from 'src/providers/ClientProvider';
import { dummyPatient } from 'src/scenes/ToDos/ToDoList/components/model';
import usePatients from './usePatients';

const usePatient = (
  patientId?: string,
  options?: UseQueryOptions<Patient | undefined, unknown, Patient>
) => {
  const { user } = useUser();
  const { isActivelyTouring } = useTourGuideMaster();
  const { data: patients } = usePatients();

  const query = useQuery({
    queryKey: [QueryKeys.PATIENT, patientId, user.sourceId],
    queryFn: () => {
      if (patientId) {
        return getPatient(user.sourceId, patientId);
      }
    },
    enabled: !!patientId && !!patients?.find((patient) => patient.patientId === patientId),
    suspense: true,
    ...options
  });

  const showDummyData = !patientId && isActivelyTouring;
  const data = showDummyData ? dummyPatient : query.data;

  return {
    ...query,
    data
  };
};

export default usePatient;
