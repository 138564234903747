import { useRoute } from '@react-navigation/native';
import { defaults, isEmpty, pick } from 'lodash';
import { useAuth } from 'src/providers/AuthProvider';
import { trackVisit, UTMParams } from 'src/utils/utmTracking';
import { Client } from 'src/interfaces';
import { TRACKING_EVENTS } from 'src/constants';
import { useQuery } from 'react-query';
import { useGoogleTagManager } from 'src/providers/GoogleTagManager/index.web';
import { useCallback, useEffect } from 'react';
import { queueEvent } from 'src/utils/utmTracking/event';

interface Options extends Partial<Pick<Client, 'practiceId' | 'sourceId' | 'clientId'>> {
  initialEventName?: TRACKING_EVENTS;
}
export const useTrackVisit = ({ initialEventName, ...sessionInfo }: Options) => {
  const { params, path } = useRoute();
  const { pushEvent } = useGoogleTagManager();
  const { _user: user, ready } = useAuth();
  const utmParams = pick((params || {}) as UTMParams, [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term'
  ]);

  const trackEvent = useCallback(
    (eventName: TRACKING_EVENTS) => {
      if (!isEmpty(utmParams)) {
        queueEvent(eventName);
      }
      pushEvent(eventName);
    },
    [pushEvent, utmParams]
  );

  useEffect(() => {
    if (initialEventName) {
      trackEvent(initialEventName);
    }
  }, [initialEventName, trackEvent]);

  const query = useQuery({
    queryKey: ['trackVisit', path, initialEventName, utmParams],
    queryFn: async () => {
      const data = await trackVisit(utmParams, path, defaults(sessionInfo, user));
      if (initialEventName) {
        trackEvent(initialEventName);
      }
      return data?.data;
    },
    enabled: !isEmpty(utmParams) && ready
  });

  return { trackEvent, ...query };
};

export default useTrackVisit;
